<!-- @format -->
<script lang="ts">
  import type { StoresKey } from "~/stores";
  import Title from "../common/Title.svelte";
  import TwoItemCardLink from "../carousels/TwoItemCardLink.svelte";
  import TwoItemCard from "../common/TwoItemCard.svelte";
  import type { SurfHouse } from "~/mall-of-tripla";
  import type { CarouselInfo } from "../carousels/utils";
  import { onMount } from "svelte";
  import Swiper from "swiper";
  import { Navigation } from "swiper/modules";
  
  let class_list = "";
  export { class_list as class };
  export let request_store_key: StoresKey;
  export let carouselInfo: CarouselInfo;
  export let surfHouseEvents: SurfHouse[];
  const {
    titleTextColor,
    textColor,
    textboxBackgroundColor,
    linkTextbox,
    linkTitle,
    logo,
  } = carouselInfo;

  // Create a new array with the link card in the middle
  $: processedEvents = linkTextbox 
    ? [...surfHouseEvents.slice(0, 1), { id: 0 } as SurfHouse, ...surfHouseEvents.slice(1)]
    : surfHouseEvents;

  let swiperContainer: HTMLElement;
  let swiper: Swiper;

  onMount(() => {
    if (!swiperContainer) return;

    swiper = new Swiper(swiperContainer, {
      modules: [Navigation],
      slidesPerView: 1.2,
      spaceBetween: 20,
      centeredSlides: true,
      loop: true,
      navigation: {
        nextEl: ".button-next",
        prevEl: ".button-prev",
      },
      breakpoints: {
        576: {
          slidesPerView: 1.5,
        },
        768: {
          slidesPerView: 2,
          centeredSlides: false,
        },
        992: {
          slidesPerView: 2.5,
          centeredSlides: true,
        }
      },
      on: {
        init: function () {
          swiperContainer.style.visibility = "visible";
        },
      },
    });

    return () => {
      if (swiper) swiper.destroy();
    };
  });
</script>

<div class="tripla-surfhouse-lift {class_list}">
  {#if processedEvents && processedEvents.length && linkTextbox}
    <div class="lift-content">
      {#if linkTitle}
        <Title {linkTitle} {titleTextColor} />
      {/if}
      <div class="swiper-container" bind:this={swiperContainer}>
        <button class="button-prev" aria-label="Previous slide">
          <i class="bi bi-chevron-left shadow" />
        </button>

        <div class="swiper-wrapper">
          {#each processedEvents as event}
            <div class="swiper-slide">
              {#if event.id !== 0}
                <TwoItemCard
                  props={{
                    image: event.image.thumbnail,
                    link: event.link,
                    title: event.heading,
                    description: event.excerpt.plain,
                  }}
                  {request_store_key}
                />
              {:else}
                <TwoItemCardLink
                  {textColor}
                  backgroundColor={textboxBackgroundColor}
                  {linkTextbox}
                  {logo}
                />
              {/if}
            </div>
          {/each}
        </div>

        <button class="button-next" aria-label="Next slide">
          <i class="bi bi-chevron-right shadow" />
        </button>
      </div>
    </div>
  {/if}
</div>

<style lang="scss">
  .tripla-surfhouse-lift {
    background-color: var(--brand-color-4);
    padding: 3rem 0;
    @media screen and (min-width: 992px) {
      padding: 6rem 0;
    }

    .lift-content {
      position: relative;
    }

    .swiper-container {
      visibility: hidden;
      padding: 0 24px;
      position: relative;

      :global(.swiper-slide) {
        height: auto;
        display: flex;

        :global(.two-item-card) {
          height: 100%;
          display: flex;
          flex-direction: column;
          width: 100%;

          :global(.image-side),
          :global(.content-side) {
            flex: 1;
            min-height: 0;
          }
        }
      }
    }

    .button-next,
    .button-prev {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-accent-primary);
      min-width: 30px;
      width: 40px;
      border: none;
      background: none;
      padding: 0;

      @media screen and (max-width: 767px) {
        width: 13% !important;
      }

      i {
        background-color: #fff;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        @media screen and (min-width: 576px) {
          font-size: 26px;
        }
      }
    }

    .button-prev {
      left: 12px;
      i {
        padding: 8px 10px 8px 6px;
      }
    }

    .button-next {
      right: 12px;
      i {
        padding: 8px 6px 8px 10px;
      }
    }
  }
</style>
